import type { ReduxSagaModel } from './types';
import { history, resetAllModelStates } from 'umi';
import {
  AmplifyLogin,
  AmplifyLogout,
  AmplifyForgotPassword,
  AmplifyNewPassword,
} from '@/services/login';
import { setAuthority } from '@/utils/authority';
import { getPageQuery, redirectToURLFromPageQuery } from '@/utils/utils';
import User from '@/services/user';
import { sgcall } from '@/utils/reduxSaga';
import { handleSystemError } from '@/utils/handleError';
import { logger } from '@/utils/logger';
import { assertIsDefined } from '@/utils/typeChecks';

const ns = 'app.models.login';

export type State = {
  currentAuthority: string;
  status: string | undefined;
  user: unknown | undefined;
  error: unknown | undefined;
  email: string | undefined;
};

const initialState: State = {
  currentAuthority: 'guest',
  status: undefined,
  user: undefined,
  error: undefined,
  email: undefined,
};

type ChangeLoginStatusAction = {
  type: 'changeLoginStatus';
  payload: Partial<State>;
};

const handleResponse = (status: string): void => {
  if (status === 'new_password_required') {
    history.push('/account/new-password');
  } else if (status === 'ok') {
    redirectToURLFromPageQuery();
  }
};

const LoginModel: ReduxSagaModel<
  State,
  {
    changeLoginStatus: ChangeLoginStatusAction;
  }
> = {
  namespace: 'login',
  state: initialState,
  effects: {
    *login({ payload }, { put }) {
      try {
        logger.debug(payload, { label: 'app.models.login.login.payload' });
        const response = yield* sgcall(() => AmplifyLogin(payload));
        logger.debug({ label: `${ns}.login.response`, message: response });

        yield put<ChangeLoginStatusAction>({ type: 'changeLoginStatus', payload: response });

        handleResponse(response.status);

        if (payload?.isFirstLogin === '1') {
          const { firstName, lastName } = payload;
          const rs = yield* sgcall(User.getCurrentAuthenticatedUser);
          assertIsDefined(rs, 'BEEM230905050300');
          yield* sgcall(() => User.updateUserDetails({ id: rs.id, firstName, lastName }));
          window.location.reload();
        }
      } catch (e) {
        handleSystemError(e, 'BEEM230817092158');
      }
    },

    *newPassword({ payload }, { put }) {
      logger.debug(payload, { label: 'app.models.login.newPassword.payload' });
      const response = yield* sgcall(() => AmplifyNewPassword(payload));
      logger.debug({ label: `${ns}.newPassword.response`, message: response });

      yield put<ChangeLoginStatusAction>({ type: 'changeLoginStatus', payload: response });

      handleResponse(response.status);
    },

    *forgotPassword({ payload }, { put }) {
      const { username, newPassword, firstName, lastName } = payload;

      const currentUser = yield* sgcall(User.getCurrentAuthenticatedUser);
      if (currentUser) {
        const logoutResponse = yield* sgcall(() => AmplifyLogout());
        logger.debug({ label: `${ns}.forgotPassword.logoutResponse`, message: logoutResponse });
      }

      logger.debug(payload, { label: 'app.models.login.forgotPassword.payload' });
      const response = yield* sgcall(() => AmplifyForgotPassword(payload));
      logger.debug({ label: `${ns}.forgotPassword.response`, message: response });

      yield put<ChangeLoginStatusAction>({ type: 'changeLoginStatus', payload: response });

      const isChangeSuccess = response.status === 'change_success';

      if (payload?.isFirstLogin === '1' && isChangeSuccess) {
        yield put({
          type: 'login',
          payload: {
            email: username,
            password: newPassword,
            firstName,
            lastName,
            isFirstLogin: payload.isFirstLogin,
          },
        });
        return;
      }

      if (isChangeSuccess) {
        window.location.href = '/';
      }
    },

    *resetPasswordForAnotherUser({ payload }) {
      yield* sgcall(() => AmplifyForgotPassword(payload));
    },

    *logout({ payload = {} }, { put }) {
      const { redirectTo } = payload;
      logger.debug(payload, { label: 'app.models.login.logout.payload' });
      const { redirect } = getPageQuery(); // Note: There may be security issues, please note
      const response = yield* sgcall(() => AmplifyLogout());
      logger.debug({ label: `${ns}.logout.response`, message: response });

      yield put<ChangeLoginStatusAction>({ type: 'changeLoginStatus', payload: response });

      yield* resetAllModelStates(put);

      if (window.location.pathname !== '/login' && !redirect) {
        history.push(redirectTo || '/login');
      }
    },
  },
  reducers: {
    resetAll() {
      return { ...initialState };
    },
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.currentAuthority);
      return {
        ...state,
        status: payload.status,
        user: payload.user,
        error: payload.error,
        email: payload.email,
      };
    },
  },
};

export default LoginModel;
