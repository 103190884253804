/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
import { getDvaApp } from 'umi';
import { Auth } from '@/aws-config';
import User from '@/services/user';
// import Source from '@/services/source';
// import DataObject from '@/services/dataObject';
import Dataset from '@/services/dataset';
import { graphql } from './graphql';
import { assertIsString } from './typeChecks';

async function checkAllowedSuperAdminUser(): Promise<void> {
  const userId = (await Auth.currentAuthenticatedUser()).getUsername();
  if (
    userId !== '3ce2df7a-cd74-48d4-a366-23d03d4bfaca' &&
    userId !== '7c5d65b8-c051-7002-99cf-a917ffccb7ab'
  ) {
    throw new Error('checkAllowedSuperAdminUser failed');
  }
}

export async function __graphql(command: any, variables: any, rnt = false): Promise<unknown> {
  await checkAllowedSuperAdminUser();
  return graphql(command, variables, rnt);
}

export async function refreshAllDatasetsInGivenFolder(folderId: string) {
  const datasets = await Dataset.getDatasetsByFolder(folderId);
  return await Promise.all(
    datasets.map(async (dataset) => {
      return await Dataset.refresh(dataset.cloudResourceId);
    }),
  );
}

export async function updateCronExpressionForAllDatasetsWithNamePrefix(
  namePrefix: string,
  hoursToAdd: number,
  minutesToAdd: number,
  confirmed = false,
) {
  const reduxState = getDvaApp()._store.getState().organization;
  const { currentWorkspace } = reduxState;
  assertIsString(currentWorkspace, 'BEEM231103210252');
  let datasets = await Dataset.getDatasetsOwnByWorkspace(currentWorkspace);
  datasets = datasets.filter((el) => el.name.toLowerCase().startsWith(namePrefix.toLowerCase()));
  for (let i = 0; i < datasets.length; i += 1) {
    const { id, name, trigger, triggerDetails } = datasets[i];
    if (trigger === 'CRON') {
      const currentCron = triggerDetails.cron;
      const cronComponents = currentCron.split(' ');
      cronComponents[0] = String(parseInt(cronComponents[0], 10) + minutesToAdd);
      cronComponents[1] = String(parseInt(cronComponents[1], 10) + hoursToAdd);
      const newCron = cronComponents.join(' ');
      console.log(`beemTools: ${id} | ${name} | ${currentCron} | ${newCron}`);
      if (confirmed) {
        const dataset = await Dataset.getDatasetById(id);
        const sqlQuery = await dataset.getSavedCode();
        await dataset.deploy({
          sqlQuery,
          trigger,
          triggerDetails: { ...triggerDetails, cron: newCron },
        });
        console.log(`beemTools: new cron is deployed`);
      }
    }
  }
}

export async function createUserIfNotExist(
  email: string,
  organizationId: string,
  workspaceId: string,
): Promise<void> {
  await checkAllowedSuperAdminUser();
  await User.createIfNotExist(email, organizationId, workspaceId);
}

// export async function auth0CreateUser(
//   email: string,
//   organizationName: string,
//   workspaceName: string,
// ): Promise<void> {
//   try {
//     const userMetadata = {
//       organization_name: organizationName,
//       workspace_name: workspaceName,
//     };

//     // call auth0 create user graphql
//     const { id } = await graphql('auth0CreateUser', {
//       query: { email, userMetadata: JSON.stringify(userMetadata) },
//     });
//     console.log(`beemTools: Auth0 user ${id} created.`);
//   } catch (error) {
//     console.error('beemTools.auth0CreateUser ERROR:', error);
//   }
// }

// disabled on 240215 => to remove after 250215
// export async function migrateConnectionsToBackfillWorkspaceId(
//   pOrgId = '',
//   pConnectionId = '',
//   confirmed = false,
// ) {
//   async function f1() {
//     const orgIds: string[] = [];
//     let nextToken: string | undefined;
//     do {
//       const rs = await graphql('listOrganizations', { nextToken });
//       const ids = rs.items.map((el) => el.id);
//       if (ids.length) orgIds.push(...ids);
//       nextToken = rs.nextToken;
//     } while (nextToken);
//     return orgIds;
//   }

//   async function f2(orgId: string) {
//     const connections: { id: string; workspaceId?: string }[] = [];
//     let nextToken2: string | undefined;
//     do {
//       const rs2 = await graphql('listConnectionsByOrganizationFivetranOnlyTwo', {
//         organizationId: orgId,
//         nextToken: nextToken2,
//       });
//       const news = rs2.items.map((el) => ({ id: el.id, workspaceId: el.workspaceId }));
//       if (news.length) connections.push(...news);
//       nextToken2 = rs2.nextToken;
//     } while (nextToken2);
//     return connections;
//   }

//   async function f3(orgId: string) {
//     const connections: { id: string; workspaceId?: string }[] = [];
//     let nextToken3: string | undefined;
//     do {
//       const rs3 = await graphql('listConnectionsByOrganizationNativeOnlyTwo', {
//         organizationId: orgId,
//         nextToken: nextToken3,
//       });
//       const news = rs3.items.map((el) => ({ id: el.id, workspaceId: el.workspaceId }));
//       if (news.length) connections.push(...news);
//       nextToken3 = rs3.nextToken;
//     } while (nextToken3);
//     return connections;
//   }

//   const defaultWorkspace = {
//     '5040ddc8-5331-48d5-9efe-b1f13f004939': 'ef040ff5-8c68-4756-b5b7-3e2c42701f9f',
//     '521f2c4a-a0d6-48de-8459-4e4d74663d93': '5afc0d4d-d902-4124-a704-c27b7f0cf698',
//     'db1ebcda-1462-4965-813e-2fdf10990083': '254c6601-25a4-4bef-a56c-1062c4deb766',
//     'f2e9ba97-f3ca-476c-bc24-8e89958c41f5': '6528da8f-f41a-4e41-8b4a-8c47782116db',
//     '8ae0194d-8b90-49eb-8847-af9bdac5bf8b': '0da1c390-d1cc-40ac-a50a-af622a8f7ad8',
//     '1ae52fd2-d036-416c-be9d-c3d60142a656': '2e003d0f-20b8-45d3-96ee-d6a2e9c7b899',
//     'bec0f9b6-8980-444c-9f96-86886175758f': '4ed777f0-688e-489e-8896-b398a812b729',
//     '5c248273-b60e-4ea0-96f3-0c11884422ee': 'bb13d430-177a-4958-a695-5d7ffe01e46f',
//     '7897454a-e995-4f2f-86c9-6c553f9aa8bc': '16a9c6da-c52b-4997-8612-a4b185c635f6',
//   };

//   const userId = (await Auth.currentAuthenticatedUser()).getUsername();
//   if (
//     userId !== '3ce2df7a-cd74-48d4-a366-23d03d4bfaca' &&
//     userId !== '7c5d65b8-c051-7002-99cf-a917ffccb7ab'
//   )
//     return;

//   const orgIds = await f1();
//   for (let i = 0; i < orgIds.length; i += 1) {
//     const orgId = orgIds[i];
//     // eslint-disable-next-line no-continue
//     if (pOrgId && pOrgId !== orgId) continue;
//     const connections = (await f2(orgId)).concat(await f3(orgId));
//     for (let j = 0; j < connections.length; j += 1) {
//       const { id, workspaceId } = connections[j];
//       // eslint-disable-next-line no-continue
//       if (pConnectionId && pConnectionId !== id) continue;
//       if (!workspaceId) {
//         const sources = await graphql('listSourcesByConnection', { connectionId: id });
//         if (sources.items.length > 1) {
//           console.log(`beemTools: ${orgId} | ${id} | INVALID | sources.items.length > 1`);
//         } else {
//           let workspaceIdToSet: string;
//           if (sources.items.length === 1) {
//             const source = await graphql('getSource', { id: sources.items[0].id });
//             workspaceIdToSet = source.workspaceId;
//           } else {
//             workspaceIdToSet = defaultWorkspace[orgId];
//           }
//           console.log(
//             `beemTools: ${orgId} | ${id} | ${workspaceIdToSet} | ${sources.items.length}`,
//           );
//           if (confirmed) {
//             await graphql('updateConnection', { input: { id, workspaceId: workspaceIdToSet } });
//             console.log(`beemTools: new workspaceId is set`);
//           }
//         }
//       }
//     }
//   }
// }

// disabled on 240325 => to remove after 250325
// export async function migrateDataObjectsToBackfillWorkspaceIdAndViewName(confirmed = false) {
//   async function f1() {
//     const doIds: string[] = [];
//     let nextToken: string | undefined;
//     do {
//       const rs = await graphql('listDataObjects', { nextToken });
//       const ids = rs.items.map((el) => el.id);
//       if (ids.length) doIds.push(...ids);
//       nextToken = rs.nextToken;
//     } while (nextToken);
//     return doIds;
//   }

//   const userId = (await Auth.currentAuthenticatedUser()).getUsername();
//   if (
//     userId !== '3ce2df7a-cd74-48d4-a366-23d03d4bfaca' &&
//     userId !== '7c5d65b8-c051-7002-99cf-a917ffccb7ab'
//   )
//     return;

//   const doIds = await f1();
//   const workspaceIdBySourceId: Record<string, string> = {};
//   for (let i = 0; i < doIds.length; i += 1) {
//     const doId = doIds[i];
//     const dataObj = await DataObject.getDataObjectById(doId);
//     const { sourceId } = dataObj;
//     const viewName = dataObj.dbViewName;
//     if (!workspaceIdBySourceId[sourceId]) {
//       const source = await Source.getSourceById(sourceId);
//       workspaceIdBySourceId[sourceId] = source.workspaceId;
//     }
//     const workspaceId = workspaceIdBySourceId[sourceId];
//     console.log(`beemTools: ${doId} | ${viewName} | ${workspaceId}`);

//     if (confirmed) {
//       await graphql('updateDataObject', { input: { id: doId, viewName, workspaceId } });
//       console.log(`beemTools: viewName and workspaceId are set`);
//     }
//   }
// }
