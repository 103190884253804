import React from 'react';
import type { StyletronComponent } from 'styletron-react';
import { createStyled, withStyle } from 'styletron-react'; // withStyle is not deprecated, withStyleDeep is, this is a mistake in the .d.ts file
// "withStyleDeep has been deprecated in v5 of Styletron. withStyle now performs a deep object merge by default as this was what was generally desired."
import type { StyleObject } from 'styletron-standard';
import { driver, getInitialStyle } from 'styletron-standard';

export const THEME = {
  colors: {
    black: '#000',
    white: '#fff',
    primary: '#0067F4',
    background: '#0a132b',
    green: '#1cc23a',
    greenHover: '#1fd242',
    defaultGray: '#B5BFD2',
    yellow: '#ffc600',
    teal: '#42B9BB',
    purple: '#A57AF4',
    red: '#E43482',
    clearButton: '#F02929',
    orange: '#FCA95A',
    backgroundGrey: '#f0f2f5',
    componentBackground: '#f9fbfc',
    catalogIconBackground: '#fff5eb',
    catalogTableBackground: '#fff',
    catalogTableBorder: '#dadee6',
    catalogTableBorderHover: '#2267f4',
    catalogTableShadowHover: 'rgba(34,103,244,.2)',
    catalogTableRowTitle: '#a9adb5',
    catalogTableRowNameHover: '#0067F4',
    sidebarItem: '#9a9ea7',
    sidebarItemSelected: '#fcc401',
    avatarDropdownItemHover: '#2c78f5',
    avatarDropdownItemBackgroundHover: '#e5f0fe',
    avatarDropdownLogoutHover: '#f13736',
    avatarDropdownLogoutBackgroundHover: '#fde8e8',
    flowStateLive: '#87de97',
    flowStatePaused: '#bbbfc7',
    flowTableBackgroundPaused: '#f0f2f5',
    tabbedPagePrimary: '#0067f4',
    tabbedPageUnselectedTab: '#6d7281',
    tabbedPageBorder: '#dadee6',
    tabbedPagePrimaryBg: '#fff',
    tabbedPageSecondaryBg: '#f9fafb',
    selectLangShadow: '#cce1fd',
    selectLangBorderFocus: '#0067f4',
    integrationCardBg: '#fff',
    integrationCardBorder: '#dadee6',
    addIntegrationCard: '#0067f4',
    integrationDetailsBg: '#f4f5f7',
    integrationDetailsBorder: '#dadee6',
    integrationSuccessBorder: '#dadfe8',
    dropdownBorder: '#dadee6',
    alerts: {
      warningBorder: '#ffA800',
      warningBackground: '#fdeecc',
    },
    progressBar: {
      background: '#010818',
    },
    dataTable: {
      background: '#F8F9FB99',
      shadow: '#FFFFFFB3',
    },
  },
  transitions: {
    hoverEffect: '0.3s',
  },
  textColor: {
    grey: '#6B707F',
  },
};

const ThemeContext = React.createContext(THEME);

const ThemeProvider = ({ children }: { children: React.ComponentType }) => (
  <ThemeContext.Provider value={THEME}>{children}</ThemeContext.Provider>
);

const wrapper = <P extends Record<string, unknown>>(
  StyledComponent: React.ComponentType<P>,
): React.ComponentType<P> =>
  function withThemeHOC(props: P) {
    return (
      <ThemeContext.Consumer>
        {(theme) => <StyledComponent {...props} $theme={theme} />}
      </ThemeContext.Consumer>
    );
  };

const styled = createStyled({ wrapper, getInitialStyle, driver }) as {
  <
    C extends keyof JSX.IntrinsicElements | React.ComponentType<any>,
    P extends Record<string, unknown>,
  >(
    component: C,
    style: (props: P & { $theme: typeof THEME }) => StyleObject,
  ): StyletronComponent<
    Pick<React.ComponentProps<C>, Exclude<keyof React.ComponentProps<C>, { className: string }>> & P
  >;
  <C extends keyof JSX.IntrinsicElements | React.ComponentType<any>>(
    component: C,
    style: StyleObject,
  ): StyletronComponent<
    Pick<React.ComponentProps<C>, Exclude<keyof React.ComponentProps<C>, { className: string }>>
  >;
};

export { ThemeContext, ThemeProvider, styled, withStyle };
