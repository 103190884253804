import type * as A from '@/api';
import type { PartialBy, Column, PipelineResult, SqlQueryEtc } from './types';
import type { TriggerDetails } from './triggerDetails';
import _ from 'underscore';
import { v4 as uuid } from 'uuid';
import { Trigger, PipelineResultStatus, ModelSortDirection } from '@/api';
import { Auth } from '@/aws-config';
import { ColumnDataType } from '@/api';
import { deleteAllSqlQueriesByDataset } from './common';
import User from './user';
import Workspace from './workspace';
import DatasetLite from './datasetLite';
import DatasetTest from './datasetTest';
import Download from './download';
import TriggerDetailsService from './triggerDetails';
import * as DatasetCommon from './dataset.common';
import { graphql } from '@/utils/graphql';
import { addBackendSuffix, makeNameSqlCompatible } from '@/utils/namingConvention';
import { logger } from '@/utils/logger';
import { assertIsDefined, assertIsNumber, assertIsString } from '@/utils/typeChecks';
import { BeemSqlParser } from '@/utils/beemSqlParser';

const ns = 'app.services.dataset';

type DatasetConstructorInput = A.GetDatasetQuery['getDataset'] & {
  version?: number;
  beemAgentInfo?: string;
}; // version can be undefined when we want to get the latest version, without knowing that version number

type DatasetCreateInput = Omit<
  PartialBy<DatasetConstructorInput, 'id' | 'ownerId' | 'cloudResourceId'>,
  'owner' | 'isDraft' | 'trigger' | 'viewName' | 'workspace'
>;

type DatasetUpdateInput = Partial<
  Omit<
    DatasetConstructorInput,
    'owner' | 'isDraft' | 'trigger' | 'triggerDetails' | 'viewName' | 'workspace'
  >
>;

type DatasetSaveLocallyInput = {
  sqlQuery: string;
  trigger: Trigger;
  triggerDetails: TriggerDetails;
};

function temp(input: DatasetConstructorInput) {
  // this part is needed until migrating all datasets to have
  // version and status in their cloudResourceInfo field
  const { cloudResourceInfo } = input;
  if (cloudResourceInfo && !cloudResourceInfo.includes('"version":')) {
    const cloudResourceInfoObj = JSON.parse(cloudResourceInfo);
    cloudResourceInfoObj.version = Math.floor(Date.parse(cloudResourceInfoObj.lastUpdated) / 1000);
    cloudResourceInfoObj.status = PipelineResultStatus.SUCCESS;
    // eslint-disable-next-line no-param-reassign
    input.cloudResourceInfo = JSON.stringify(cloudResourceInfoObj);
  }
}

function getCronVal(t: Trigger, d: TriggerDetails): string | undefined {
  if (t === Trigger.MANUAL) return undefined;
  return d.cron;
}

async function checkViewNameExists(workspaceId: string, viewName: string, datasetId?: string) {
  const filter = {
    workspaceId: { eq: workspaceId },
    id: datasetId ? { ne: datasetId } : undefined,
  };
  const data = await graphql('listDatasetsByViewName', { viewName, filter });
  if (data.items.length > 0) {
    const el = data.items[0];
    throw new Error(
      `Dataset with the view name ${viewName} already exists with the name ${el.name}.`,
    );
  }
}

async function updateUtilityFieldsForSharing(
  datasetId: string,
  originWorkspaceId: string,
  workspaceId: string,
  add: boolean,
): Promise<void> {
  // update the "sharedWithWorkspaceIds" field on Dataset (this is used for graphql @auth directive)
  const data = await graphql('getDatasetOnlySharedWithWorkspaceIds', { id: datasetId });
  let sharedWithWorkspaceIds = data.sharedWithWorkspaceIds ?? [];
  sharedWithWorkspaceIds = sharedWithWorkspaceIds.filter((el) => el !== workspaceId);
  if (add) sharedWithWorkspaceIds.push(workspaceId);
  await graphql('updateDataset', { input: { id: datasetId, sharedWithWorkspaceIds } });

  // update "link" dynamodb table (this helps with query performance to search for origin workspaces)
  const linkId = `${originWorkspaceId}#${workspaceId}`;
  const found = await graphql('getDatasetShareFromWorkspaceWithWorkspaceLink', { id: linkId });
  if (add && !found) {
    await graphql('createDatasetShareFromWorkspaceWithWorkspaceLink', {
      input: { id: linkId, fromWorkspaceId: originWorkspaceId, withWorkspaceId: workspaceId },
    });
  } else if (!add && found) {
    // only remove the link if this is the last dataset which was shared
    const stillShared = await graphql('listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace', {
      workspaceId,
      originWorkspaceId: { eq: originWorkspaceId },
      limit: 1,
    });
    if (stillShared.items.length === 0) {
      await graphql('deleteDatasetShareFromWorkspaceWithWorkspaceLink', { input: { id: linkId } });
    }
  }
}

export default class Dataset {
  timestamp?: number;

  readonly id: string;

  readonly owner: User;

  readonly version: number;

  readonly isLatest: boolean;

  readonly folderId: string;

  readonly name: string;

  readonly description: string;

  readonly enableSearchByAI: boolean;

  #viewName: string;
  get viewName(): string {
    return this.#viewName;
  }
  private set viewName(i: string) {
    this.#viewName = i;
  }

  #isDraft: boolean;
  get isDraft(): boolean {
    return this.#isDraft;
  }
  private set isDraft(i: boolean) {
    this.#isDraft = i;
  }

  // this field should always store value from the deployed version, it's duplicate the SqlQueryEtc record, but for quick access without another search
  #trigger: Trigger;
  get trigger(): Trigger {
    return this.#trigger;
  }
  private set trigger(i: Trigger) {
    this.#trigger = i;
  }

  // this field should always store value from the deployed version, it's duplicate the SqlQueryEtc record, but for quick access without another search
  #triggerDetails: TriggerDetails;
  get triggerDetails(): TriggerDetails {
    return this.#triggerDetails;
  }
  private set triggerDetails(i: TriggerDetails) {
    this.#triggerDetails = i;
  }

  readonly workspace: DatasetConstructorInput['workspace'];

  readonly workspaceId: string;

  readonly workspaceSeq: number;

  readonly organizationId: string;

  readonly cloudResourceId: string;

  #stats?: {
    lastUpdated: string;
    rowCount: number;
    columns: Column[];
  };

  get lastUpdated(): string | undefined {
    return this.#stats?.lastUpdated;
  }

  get rowCount(): number | undefined {
    return this.#stats?.rowCount;
  }

  get columns() {
    return this.#stats?.columns;
  }

  get consolidatedTestResultColumns(): Column[] {
    return ['test_uuid', 'number', 'category', 'reason', 'blocking'].map((el) => ({
      id: el,
      type: ColumnDataType.STRING,
      nullable: false,
      title: '',
      dataIndex: '',
    }));
  }

  get dbSchemaName() {
    return `${addBackendSuffix(`ws${this.workspaceSeq}`)}_hub`;
  }

  get dbViewName() {
    return this.viewName;
  }

  get dbViewsQualifiedName() {
    let part1 = this.dbSchemaName;
    if (/\W/.test(part1)) part1 = `"${part1}"`;
    let part2 = this.dbViewName;
    if (/\W/.test(part2)) part2 = `"${part2}"`;
    return `${part1}.${part2}`;
  }

  get createTableStatement() {
    const columns = this.columns?.map(
      (el) => `${el.id} ${el.type} ${el.nullable ? 'NULL' : 'NOT NULL'}`,
    );
    return `CREATE TABLE ${this.dbViewsQualifiedName} (${columns?.join(', ')})`;
  }

  private constructor(input: DatasetConstructorInput) {
    temp(input);

    this.id = input.id;
    this.owner = new User(input.owner);
    this.folderId = input.folderId;
    this.name = input.name;
    this.description = input.description ?? '';
    this.enableSearchByAI = input.enableSearchByAI ?? false;
    this.#viewName = input.viewName;
    this.#isDraft = input.isDraft;
    this.#trigger = input.trigger;
    this.#triggerDetails = TriggerDetailsService.setDefaults(
      input.triggerDetails ? JSON.parse(input.triggerDetails) : {},
    );
    this.workspace = input.workspace;
    this.workspaceId = input.workspaceId;
    this.workspaceSeq = input.workspace.seq;
    this.organizationId = input.workspace.organizationId;
    this.cloudResourceId = input.cloudResourceId;

    if (this.isDraft) {
      this.version = 0;
      this.isLatest = false;
    } else {
      const { cloudResourceInfo, beemAgentInfo } = input;
      assertIsString(cloudResourceInfo, 'BEEM211105120241');
      const cloudResourceInfoObj = JSON.parse(cloudResourceInfo);
      assertIsDefined(cloudResourceInfoObj, 'BEEM211105120242');
      const latestVersion = cloudResourceInfoObj.version;
      assertIsNumber(latestVersion, 'BEEM211105120243');
      this.version = input.version ?? latestVersion;
      this.isLatest = this.version === latestVersion;
      if (this.isLatest) {
        assertIsDefined(cloudResourceInfo, 'BEEM211105120243');
        this.#stats = JSON.parse(cloudResourceInfo);
      } else {
        assertIsDefined(beemAgentInfo, 'BEEM211105120244');
        this.#stats = JSON.parse(beemAgentInfo);
      }
    }

    this.getDownloads = this.getDownloads.bind(this);
    this.getContentStart = this.getContentStart.bind(this);
    this.getConsolidatedTestResults = this.getConsolidatedTestResults.bind(this);
    this.getSqlQueryEtcChanges = this.getSqlQueryEtcChanges.bind(this);
    this.getSavedCode = this.getSavedCode.bind(this);
    this.getDeployedCode = this.getDeployedCode.bind(this);
    this.getPipelineResults = this.getPipelineResults.bind(this);
    this.getCurrentPipelineResult = this.getCurrentPipelineResult.bind(this);
    this.listenToPipelineResult = this.listenToPipelineResult.bind(this);
    this.update = this.update.bind(this);
    this.saveLocally = this.saveLocally.bind(this);
    this.deploy = this.deploy.bind(this);
    this.getSharedWithWorkspaces = this.getSharedWithWorkspaces.bind(this);
    this.share = this.share.bind(this);
    this.modifyTests = this.modifyTests.bind(this);
  }

  public clone(): Dataset {
    const datasetData: DatasetConstructorInput = {
      id: this.id,
      owner: {
        id: this.owner.id,
        email: this.owner.email,
        firstName: this.owner.firstName,
        lastName: this.owner.lastName,
      },
      folderId: this.folderId,
      name: this.name,
      description: this.description,
      enableSearchByAI: this.enableSearchByAI,
      viewName: this.viewName,
      isDraft: this.isDraft,
      trigger: this.trigger,
      workspace: this.workspace,
      workspaceId: this.workspaceId,
      cloudResourceId: this.cloudResourceId,
      version: this.version,
      ownerId: this.owner.id,
    };

    if (!this.isDraft) {
      datasetData.cloudResourceInfo = JSON.stringify(this.#stats);
    }

    const newDataset = new Dataset(datasetData);

    if (this.timestamp) {
      newDataset.timestamp = this.timestamp;
    }

    return newDataset;
  }

  async getSharedWithWorkspaces(): Promise<Workspace[]> {
    const datasetId = this.id;
    const data = await graphql('listDatasetWorkspaceSharesByDataset', { datasetId });
    return await Promise.all(
      data.items.map(async (el) => {
        return await Workspace.getWorkspaceById(el.workspaceId);
      }),
    );
  }

  async getDownloads() {
    return Download.getDownloadsByDataId(this.id);
  }

  getContentStart = DatasetCommon.getContentStart;

  async getConsolidatedTestResults(
    pageSize: number,
    startIndex: number,
  ): Promise<{ executionId: string }> {
    const data = await graphql('beemAgentGetDatasetConsolidatedTestResults', {
      query: {
        cloudResourceId: this.cloudResourceId,
        version: String(this.version), // should be a number but it doesn't hurt, we can refactor later for consistency
        pageSize,
        startIndex,
      },
    });
    return data;
  }

  async getSqlQueryEtcChanges(limit?: number): Promise<SqlQueryEtc[]> {
    const data = await graphql('sqlQueriesByDataset', {
      datasetId: this.id,
      sortDirection: ModelSortDirection.DESC,
      limit,
    });

    // vinh408 should not be undefined
    const items = data.items.map((el) => ({
      ...el,
      trigger: el.trigger ?? this.trigger,
      triggerDetails: el.triggerDetails || JSON.stringify(this.triggerDetails),
      deployed: el.deployed ?? true,
      updatedAt: el.updatedAt ?? el.createdAt,
    }));

    return items.map((el) => {
      let { triggerDetails } = el;

      // this is for backward-compatible with the pre-2022 records
      // which used the occurrence fields instead of cron for SCHEDULED trigger
      if (el.trigger === Trigger.SCHEDULED) {
        const d = JSON.parse(triggerDetails);
        if (d.occurrence) {
          triggerDetails = JSON.stringify({
            cron: TriggerDetailsService.getCronFromScheduledTriggerComponents(d),
          });
        }
      }

      return {
        id: el.id,
        timestamp: Math.floor(Date.parse(el.updatedAt) / 1000),
        sqlQuery: el.sqlQuery,
        trigger: el.trigger,
        triggerDetails: TriggerDetailsService.setDefaults(JSON.parse(triggerDetails)),
        deployed: el.deployed,
      };
    });
  }

  async getSavedCode(): Promise<string> {
    const items = await this.getSqlQueryEtcChanges(1);
    if (items.length === 0) return '';
    return items[0].sqlQuery;
  }

  async getDeployedCode(): Promise<string> {
    const items = await this.getSqlQueryEtcChanges(2);
    if (items.length === 0) return '';
    const found = items.find((el) => el.deployed);
    return found ? found.sqlQuery : '';
  }

  async getPipelineResults(timestamp?: number): Promise<PipelineResult[]> {
    if (!this.cloudResourceId) return [];
    const data = await graphql('pipelineResultsByRecordCloudResourceId', {
      recordCloudResourceId: this.cloudResourceId,
      timestamp: timestamp ? { eq: timestamp } : undefined,
      sortDirection: ModelSortDirection.DESC,
    });
    return data.items;
  }

  async getCurrentPipelineResult(): Promise<PipelineResult> {
    const rs = await this.getPipelineResults(this.version);
    if (rs.length !== 1) {
      throw new Error(`BEEM230903022100.1 ${this.id} ${this.version} ${rs.length}`);
    }
    return rs[0];
  }

  listenToPipelineResult = DatasetCommon.listenToPipelineResult;

  // update is only for the app db, for non-remote values
  async update(input: DatasetUpdateInput): Promise<Dataset> {
    logger.info({ label: 'app.services.dataset.update.input', message: input });
    const { name } = input;

    let viewName: string | undefined;
    if (name) {
      viewName = makeNameSqlCompatible(name);
      await checkViewNameExists(this.workspaceId, viewName, this.id);
    }

    await graphql('updateDataset', { input: { ...input, viewName, id: this.id } });

    if (viewName) {
      const changes = await this.getSqlQueryEtcChanges(1);
      if (changes.length < 1)
        throw new Error(
          'Unexpected - Cannot change viewName when there is no recorded SqlQuery yet',
        );
      const { id, sqlQuery, trigger, triggerDetails } = changes[0];
      this.viewName = viewName;
      await graphql('beemAgentDeployDatasetSqlQuery', {
        input: {
          id,
          datasetId: this.id,
          datasetCloudResourceId: this.cloudResourceId,
          workspaceId: this.workspaceId,
          sqlQuery,
          trigger,
          triggerDetails: JSON.stringify({ ...triggerDetails }),
          deployed: true,
          dbSchemaName: this.dbSchemaName,
          dbViewName: this.dbViewName,
        },
      });
    }

    const newDataset = this.clone();
    Object.assign(newDataset, input);

    return newDataset;
  }

  async saveLocally(input: DatasetSaveLocallyInput) {
    logger.info({ label: `${ns}.saveLocally.input`, message: input });
    const { sqlQuery, trigger, triggerDetails } = input;

    const gqlInput = {
      datasetId: this.id,
      workspaceId: this.workspaceId,
      sqlQuery,
      trigger,
      triggerDetails: JSON.stringify({ ...triggerDetails }),
      deployed: false,
    };

    const temp1 = await this.getSqlQueryEtcChanges(1);

    if (temp1.length > 0 && temp1[0].deployed === false) {
      await graphql('updateDatasetSqlQuery', { input: { ...gqlInput, id: temp1[0].id } });
    } else {
      await graphql('createDatasetSqlQuery', { input: gqlInput });
    }
  }

  async deploy(input: DatasetSaveLocallyInput) {
    logger.info({ label: `${ns}.deploy.input`, message: input });
    const { sqlQuery, trigger, triggerDetails } = input;

    // update the trigger (cron) in customer's account through the beem agent
    const previousCron = getCronVal(this.trigger, this.triggerDetails); // these top-level fields always contain the last deployed values
    const previousTimezone = this.triggerDetails.timezone;
    const cron = getCronVal(trigger, triggerDetails);
    const { timezone } = triggerDetails;
    if (cron !== previousCron || previousTimezone !== timezone) {
      await graphql('beemAgentModifyDataset', {
        query: {
          cloudResourceId: this.cloudResourceId,
          previousCron,
          previousTimezone,
          cron,
          timezone,
        },
      });
    }

    // depending on whether save was clicked before deploy, we create or update the sql query etc record
    const gqlInput = {
      datasetId: this.id,
      datasetCloudResourceId: this.cloudResourceId,
      workspaceId: this.workspaceId,
      sqlQuery,
      trigger,
      triggerDetails: JSON.stringify({ ...triggerDetails }),
      deployed: true,
      dbSchemaName: this.dbSchemaName, // actually, should only need for draft datasets
      dbViewName: this.dbViewName, // actually, should only need for draft datasets
    };
    const temp1 = await this.getSqlQueryEtcChanges(1);
    const id = temp1.length > 0 && temp1[0].deployed === false ? temp1[0].id : undefined;
    await graphql('beemAgentDeployDatasetSqlQuery', { input: { id, ...gqlInput } });

    // change draft status if necessary
    if (this.isDraft) {
      // then update the app db
      const now = Date.now();
      const cloudResourceInfo = JSON.stringify({
        version: Math.floor(now / 1000),
        status: PipelineResultStatus.SUCCESS,
        lastUpdated: new Date(now).toISOString(),
        rowCount: 0,
        columns: [],
      });
      await graphql('updateDataset', {
        input: { id: this.id, isDraft: false, cloudResourceInfo },
      });
      this.isDraft = false;
    }

    // update the top-level trigger fields for quick access
    await graphql('updateDataset', {
      input: {
        id: this.id,
        trigger,
        triggerDetails: JSON.stringify({ ...triggerDetails }),
      },
    });
    this.trigger = trigger;
    this.triggerDetails = triggerDetails;

    // parse the query for parent datasets
    try {
      const beemSqlParser = new BeemSqlParser({ dbEngine: 'Redshift' });
      beemSqlParser.parse(sqlQuery);
      const tables = beemSqlParser.getTables();

      // map the tables to their datasetId
      const tablesWithId = await Promise.all(
        [...tables].map(async (el: any) => {
          const { schema, table } = el;
          const seqNumber = schema.split('_')[0].slice(2).match(/^\d+/)[0];
          const refType = schema.split('_')[1];

          // get workspace from seqNumber and orgId
          const workspaceFilter = {
            seq: { eq: seqNumber },
          };
          const workspaces = await graphql('listWorkspacesByOrganization', {
            organizationId: this.organizationId,
            filter: workspaceFilter,
          });

          // get datasetId from workspaceId and table name
          const workspaceId = workspaces.items[0]?.id;

          let datasetId = '';
          switch (refType) {
            case 'hub': {
              const data = await graphql('listDatasetsByViewName', {
                viewName: table,
                filter: {
                  workspaceId: { eq: workspaceId },
                },
              });
              datasetId = data?.items[0]?.id;
              break;
            }
            case 'catalog': {
              const data = await graphql('listDataObjectsByViewName', {
                viewName: table,
                filter: {
                  workspaceId: { eq: workspaceId },
                },
              });
              datasetId = data?.items[0]?.id;
              break;
            }

            default:
          }

          return { schema, table, refType, datasetId };
        }),
      );

      await graphql('updateDataset', {
        input: {
          id: this.id,
          parents: JSON.stringify({ tables: tablesWithId, errors: beemSqlParser.getErrors() }),
        },
      });
    } catch (error) {
      logger.error({ label: `${ns}.deploy.parseQueryError`, message: error });
    }
  }

  async share(workspaces: Workspace[]): Promise<void> {
    const datasetId = this.id;
    const originWorkspaceId = this.workspaceId;

    // update dynamodb
    const data = await graphql('listDatasetWorkspaceSharesByDataset', { datasetId });
    const { items } = data;
    const currentSharedWithWorkspaceIds = _.pluck(items, 'workspaceId');
    const workspaceIds = _.pluck(workspaces, 'id');
    const workspaceIdsToShare = _.difference(workspaceIds, currentSharedWithWorkspaceIds);
    const workspaceIdsToUnshare = _.difference(currentSharedWithWorkspaceIds, workspaceIds);
    await Promise.all(
      workspaceIdsToShare.map(async (workspaceId) => {
        await graphql('createDatasetWorkspaceShare', {
          input: { datasetId, workspaceId, originWorkspaceId },
        });
        await updateUtilityFieldsForSharing(datasetId, originWorkspaceId, workspaceId, true);
      }),
    );
    await Promise.all(
      workspaceIdsToUnshare.map(async (workspaceId) => {
        const found = items.find((el) => el.workspaceId === workspaceId);
        assertIsDefined(found, 'BEEM220622183038');
        await graphql('deleteDatasetWorkspaceShare', { input: { id: found.id } });
        await updateUtilityFieldsForSharing(datasetId, originWorkspaceId, workspaceId, false);
      }),
    );

    // update beem agent
    const dbSharedWith = workspaces.map((el) => addBackendSuffix(`ws${el.seq}`));
    await graphql('beemAgentModifyDataset', {
      query: {
        cloudResourceId: this.cloudResourceId,
        dbSharedWith,
      },
    });
  }

  async modifyTests(): Promise<void> {
    const testDatasets = await DatasetTest.getDatasetTestsByDataset(this.id);
    await graphql('beemAgentModifyDataset', {
      query: {
        cloudResourceId: this.cloudResourceId,
        testDatasetIds: testDatasets.map((el) => el.cloudResourceId.split('.')[1]),
      },
    });
  }

  // version can be undefined when we want to get the latest version, without knowing that version number
  static async getDatasetById(id: string, version?: number): Promise<Dataset> {
    logger.info(id, { label: 'app.services.dataset.getDatasetById.id' });
    const data = await graphql('getDataset', { id });
    let beemAgentInfo: string | undefined;
    if (version) {
      ({ info: beemAgentInfo } = await graphql('beemAgentGetDatasetInfo', {
        query: { cloudResourceId: data.cloudResourceId, version: String(version) },
      }));
    }
    return new Dataset({ ...data, version, beemAgentInfo });
  }

  static async getDatasetsByFolder(folderId: string) {
    logger.info({ label: `${ns}.getDatasetsByFolder.folderId`, message: folderId });
    const data = await graphql('listDatasetsByFolder', { folderId });
    return data.items.map((el) => new Dataset(el));
  }

  static async getDatasetsOwnByWorkspace(
    workspaceId: string,
    name?: A.ModelStringKeyConditionInput,
    filter?: A.ModelDatasetFilterInput,
  ) {
    logger.info({ label: `${ns}.getDatasetsOwnByWorkspace.workspaceId`, message: workspaceId });
    const data = await graphql('listDatasetsByWorkspace', { workspaceId, name, filter });
    return data.items.map((el) => new Dataset(el));
  }

  static async getDatasetsIdAndNameOwnByWorkspace(workspaceId: string): Promise<DatasetLite[]> {
    logger.info({ label: `${ns}.getDatasetsIdAndNameOwnByWorkspace.wsId`, message: workspaceId });
    const data = await graphql('listDatasetsByWorkspaceOnlyIdAndName', { workspaceId });
    return data.items.map((el) => new DatasetLite({ id: el.id, name: el.name }));
  }

  static async getDatasetsSharedWithWorkspace(
    workspaceId: string,
    originWorkspaceId?: string,
    name?: A.ModelStringKeyConditionInput,
  ) {
    logger.info({ label: `${ns}.getDatasetsSharedWithWorkspace.wsId`, message: workspaceId });
    const data = await graphql('listDatasetWorkspaceSharesByWorkspaceAndOriginWorkspace', {
      workspaceId,
      originWorkspaceId: originWorkspaceId ? { eq: originWorkspaceId } : undefined,
    });
    if (name) {
      let nameString: string;
      if (typeof name.beginsWith === 'string') {
        nameString = name.beginsWith;
      }
      data.items = data.items.filter((d) => d.dataset.name.includes(nameString));
    }
    return data.items.map((el) => new Dataset(el.dataset));
  }

  static testSqlQuery = DatasetCommon.testSqlQuery;

  static async create(input: DatasetCreateInput): Promise<string> {
    logger.info({ label: 'app.services.dataset.create.input', message: input });
    const id = uuid();
    const { name, workspaceId } = input;
    let { ownerId, cloudResourceId } = input;
    const viewName = makeNameSqlCompatible(name);
    await checkViewNameExists(workspaceId, viewName);
    if (!cloudResourceId) {
      const workspace = await Workspace.getWorkspaceById(workspaceId);
      cloudResourceId = addBackendSuffix(`${workspace.organization.cloudResourceId}.${id}`);
    }
    if (!ownerId) ownerId = (await Auth.currentAuthenticatedUser()).getUsername();
    assertIsDefined(ownerId, 'BEEM211105120245');
    const data = await graphql('createDataset', {
      input: {
        ...input,
        id,
        viewName,
        ownerId,
        isDraft: true,
        trigger: Trigger.MANUAL,
        cloudResourceId,
      },
    });
    return data.id;
  }

  static async refresh(cloudResourceId: string): Promise<void> {
    logger.info({
      label: 'app.services.dataset.refresh.cloudResourceId',
      message: cloudResourceId,
    });
    await graphql('refreshDataset', { query: { cloudResourceId } });
  }

  static async delete({ id }: { id: string }) {
    logger.info({ label: 'app.services.dataset.delete.id', message: id });
    const r = await Dataset.getDatasetById(id);

    if (!r.isDraft) {
      // if shared with other workspaces, unshare it first
      const temp1 = await r.getSharedWithWorkspaces();
      if (temp1.length > 0) await r.share([]);

      // redeploy with MANUAL trigger to remove the remote trigger
      // but use the same "latest deployed" query, to not trigger dataset refresh
      const sqlQuery = await r.getDeployedCode();
      const trigger = Trigger.MANUAL;
      const { triggerDetails } = r;
      await r.deploy({ sqlQuery, trigger, triggerDetails });
    }

    await graphql('deleteDataset', { input: { id } });

    await deleteAllSqlQueriesByDataset(id);

    if (!r.isDraft) {
      const { cloudResourceId } = r;
      await graphql('beemAgentDeleteDatasetHistoricalData', { query: { cloudResourceId } });
    }
  }
}
