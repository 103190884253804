import * as React from 'react';

export default () => (
  <svg width="24" height="24">
    <g fill="none" fillRule="evenodd">
      <circle stroke="#DADEE6" fill="#FFF" cx="12" cy="12" r="11.5" />
      <path stroke="#0A132B" strokeWidth="2" strokeLinecap="round" d="M7 12h10" />
    </g>
  </svg>
);
