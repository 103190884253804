import { logger } from '@/utils/logger';
import Organization from '@/services/organization';
import User from '@/services/user';

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: null,
    organizations: undefined,
    workspaces: undefined,
    notifyLogout: null,
  },
  effects: {
    *fetchCurrent(_, { call, put }) {
      const response = yield call(User.getCurrentAuthenticatedUser);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },

    *fetchWorkspaces(_, { call, put }) {
      const workspaces = yield call(Organization.getWorkspacesOfCurrentUser);
      logger.info({ label: 'app.models.user.fetchWorkspaces.workspaces', message: workspaces });
      if (workspaces.length === 0) return;

      const organizations = workspaces.reduce((acc, current) => {
        const org = acc.findIndex((el) => el.id === current.organization.id);
        if (org < 0) {
          acc.push(current.organization);
        }
        return acc;
      }, []);
      logger.info(organizations, { label: 'app.models.user.fetchWorkspaces.organizations' });

      yield put({
        type: 'saveWorkspaces',
        payload: {
          organizations: organizations.sort((a, b) => a.name.localeCompare(b.name)),
          workspaces: workspaces.sort((a, b) => a.name.localeCompare(b.name)),
        },
      });
    },
    *notifyLogout({ payload }, { put }) {
      const { notifyLogout } = payload;
      yield put({
        type: 'saveNotifyLogout',
        payload: { notifyLogout },
      });
    },
    *updateUser({ payload }, { call }) {
      logger.debug({ label: 'app.models.user.updateUser', message: payload });
      yield call(User.updateUserDetails, payload);
    },
  },
  reducers: {
    saveCurrentUser(state, { payload }) {
      return { ...state, currentUser: payload };
    },
    saveWorkspaces(state, { payload }) {
      return { ...state, organizations: payload.organizations, workspaces: payload.workspaces };
    },
    saveNotifyLogout(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default UserModel;
