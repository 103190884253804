// @ts-nocheck
import { plugin } from './plugin';
import * as Plugin_0 from '/codebuild/output/src3603880104/src/app/src/app.js';
import * as Plugin_1 from '/codebuild/output/src3603880104/src/app/node_modules/umi-plugin-antd-icon-config/lib/app.js';
import * as Plugin_2 from '/codebuild/output/src3603880104/src/app/src/.umi-production/plugin-dva/runtime.tsx';
import * as Plugin_3 from '../plugin-initial-state/runtime';
import * as Plugin_4 from '/codebuild/output/src3603880104/src/app/src/.umi-production/plugin-locale/runtime.tsx';
import * as Plugin_5 from '../plugin-model/runtime';

  plugin.register({
    apply: Plugin_0,
    path: '/codebuild/output/src3603880104/src/app/src/app.js',
  });
  plugin.register({
    apply: Plugin_1,
    path: '/codebuild/output/src3603880104/src/app/node_modules/umi-plugin-antd-icon-config/lib/app.js',
  });
  plugin.register({
    apply: Plugin_2,
    path: '/codebuild/output/src3603880104/src/app/src/.umi-production/plugin-dva/runtime.tsx',
  });
  plugin.register({
    apply: Plugin_3,
    path: '../plugin-initial-state/runtime',
  });
  plugin.register({
    apply: Plugin_4,
    path: '/codebuild/output/src3603880104/src/app/src/.umi-production/plugin-locale/runtime.tsx',
  });
  plugin.register({
    apply: Plugin_5,
    path: '../plugin-model/runtime',
  });
