import type * as A from '@/api';
import type { CognitoUser } from '@aws-amplify/auth';
import type { WindowWithIntercom } from './types';
import type Organization from './organization';
import { Auth } from '@/aws-config';
import Workspace from './workspace';
import { graphql } from '@/utils/graphql';
import { capitalize } from '@/utils/text';
import { addBackendSuffix } from '@/utils/namingConvention';
import { logger } from '@/utils/logger';
import { handleUserError } from '@/utils/handleError';

const ns = 'app.services.user';

type UserConstructorInput = A.GetUserQuery['getUser'];

export default class User {
  readonly id: string;

  readonly email: string;

  readonly firstName?: string;

  readonly lastName?: string;

  readonly workspaces?: Partial<A.Workspace>[];

  readonly organizationsUser?: Partial<A.OrganizationUser>[];

  get name(): string {
    return `${capitalize(this.firstName)} ${capitalize(this.lastName)}`;
  }

  // eslint-disable-next-line class-methods-use-this
  get avatar(): string {
    return ''; // to be implemented
  }

  constructor(input: UserConstructorInput) {
    this.id = input.id;
    this.email = input.email;
    if (input.firstName || input.lastName) {
      this.firstName = input.firstName;
      this.lastName = input.lastName;
    } else {
      [this.firstName, this.lastName] = this.email.split('@')[0].split('.');
    }
    this.workspaces = input.workspaces ? input.workspaces.items.map((w) => w.workspace) : [];
    this.organizationsUser = input.organizations ? input.organizations.items : [];

    this.hasWarehouseCredentials = this.hasWarehouseCredentials.bind(this);
    this.createOrUpdateWarehouseCredentials = this.createOrUpdateWarehouseCredentials.bind(this);
    this.resetWarehouseCredentials = this.resetWarehouseCredentials.bind(this);
  }

  async hasWarehouseCredentials(
    organization: Organization,
  ): Promise<{ id: string; hasWarehouseCredentials: boolean }> {
    // vinhf migrate ID of OrganizationUser to be ${orgId}.${userId}
    const data = await graphql('listOrganizationUsersByUser', {
      userId: this.id,
      organizationId: { eq: organization.id },
    });
    if (data.items.length !== 1) throw new Error('BEEM220628095954');
    const { id, hasWarehouseCredentials } = data.items[0];
    return { id, hasWarehouseCredentials: hasWarehouseCredentials ?? false };
  }

  async createOrUpdateWarehouseCredentials(organization: Organization): Promise<string> {
    // find the workspaces in this org that this user has access to
    let workspaces = await Workspace.getWorkspacesByUser(this.id);
    workspaces = workspaces.filter((el) => el.organization.id === organization.id);

    // call the beem agent to create the warehouse user
    // or update (aka add/remove) the workspace groups
    // or delete the user if no more workspaces
    const data = await graphql('upsertUserWarehouseCredentials', {
      query: {
        orgId: organization.cloudResourceId,
        userEmail: this.email,
        workspaceSeqs: workspaces.map((el) => addBackendSuffix(String(el.seq))),
      },
    });

    // set the flag that this user now has warehouse creds for this org
    const { id, hasWarehouseCredentials } = await this.hasWarehouseCredentials(organization);
    if (workspaces.length > 0 && !hasWarehouseCredentials)
      await graphql('updateOrganizationUser', { input: { id, hasWarehouseCredentials: true } });
    else if (workspaces.length === 0 && hasWarehouseCredentials)
      await graphql('updateOrganizationUser', { input: { id, hasWarehouseCredentials: false } });

    return data.password;
  }

  async resetWarehouseCredentials(organization: Organization): Promise<string> {
    const data = await graphql('upsertUserWarehouseCredentials', {
      query: {
        orgId: organization.cloudResourceId,
        userEmail: this.email,
      },
    });
    return data.password;
  }

  static async getUserById(id: string): Promise<User> {
    logger.info(id, { label: 'app.model.user.getUserById.id' });
    const data = await graphql('getUser', { id });
    return new User(data);
  }

  static async getCurrentAuthenticatedUser(): Promise<User | null> {
    let cognitoUser: CognitoUser;
    try {
      cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      logger.info({
        label: 'app.services.user.getCurrentAuthenticatedUser.cognitoUser',
        message: cognitoUser,
      });
    } catch (err) {
      if (typeof err === 'string' && err.includes('not authenticated')) {
        logger.debug({
          label: 'app.services.user.getCurrentAuthenticatedUser.error',
          message: err,
        });
      } else {
        handleUserError(err, `${ns}.getCurrentAuthenticatedUser.error`);
      }
      return null;
    }

    const id = cognitoUser.getUsername();
    const user = await User.getUserById(id);

    // cognito attributes are not used because we're using dynamodb instead
    // so only keeping this code for future use
    // const attributes = await Auth.userAttributes(cognitoUser);
    // const emailAttr = attributes.find((a) => a.Name === 'email');
    // const email = emailAttr.getValue();

    (window as WindowWithIntercom).Intercom('update', {
      user_id: user.id,
      email: user.email,
      name: user.name,
      // TODO: created_at: attributes.created_at // Signup date as a Unix timestamp
    });

    return user;
  }

  static async createIfNotExist(
    email: string,
    organizationId: string,
    workspaceId: string,
  ): Promise<User> {
    const { items } = await graphql('listUsersByEmail', { email });

    let userId: string;
    if (items.length === 0) {
      // create the user in cognito and add the user record in dynamodb
      const { id } = await graphql('createCognitoUser', { query: { email } });
      const names = email.split('@')[0];
      await graphql('createUser', {
        input: { id, email, firstName: names.split('.')[0], lastName: names.split('.')[1] },
      });
      userId = id;
    } else {
      userId = items[0].id;
    }

    // vinh991 move code to org / ws

    // link the user with the org
    await graphql('createOrganizationUser', {
      input: { id: `${organizationId}#${userId}`, organizationId, userId },
    });

    // add the user to the workspace
    const ws = await Workspace.getWorkspaceById(workspaceId);
    await ws.addMembers([userId]);

    return User.getUserById(userId);
  }

  static async updateUserDetails(user: A.UpdateUserMutationVariables['input']): Promise<void> {
    await graphql('updateUser', {
      input: { ...user },
    });
  }
}
